/* --------------------------------
   gallery
-------------------------------- */

.gallery
{
	@extend %pos-relative;
	@extend %fl-l;
	@extend %width-100;

	& &__item { @extend %pos-relative; }

	& &__item--text
	{
		@extend %width-100;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	& &__item--image
	{
		margin: 0 -15px;
		min-height: 300px;
		@extend %bg-cover;
	}

	.row
	{
		&:nth-of-type(2n+1)
		{
			.gallery__item--text { margin-right: auto; }
		}

		&:nth-of-type(2n)
		{
			.gallery__item--text { margin-left: auto; }
		}
	}

	.section [class^="container"]  &:first-child,
	.section [class*=" container"] &:first-child
	{
		margin-bottom: -60px;
	}
}

@include min-screen($sm-width)
{
	.gallery
	{
		& &__item--text
		{
			max-width: 330px;
			padding-top: 30px;
			padding-bottom: 30px;
		}


		& &__item--image { min-height: 400px; }

		.row
		{
			&:nth-of-type(2n+1)
			{
				.gallery__item--text { padding-left: 30px; }
			}

			&:nth-of-type(2n)
			{
				.gallery__item--text { padding-right: 30px; }
			}
		}

		.section [class^="container"]  &:first-child,
		.section [class*=" container"] &:first-child
		{
			margin-bottom: -80px;
		}
	}
}

@include min-screen($md-width)
{
	.gallery
	{
		& &__item--text { max-width: 440px; }

		& &__item--image { min-height: 500px; }

		.row
		{
			&:nth-of-type(2n+1)
			{
				.gallery__item--text { padding-left: 50px; }
			}

			&:nth-of-type(2n)
			{
				.gallery__item--text { padding-right: 50px; }
			}
		}

		.section [class^="container"]  &:first-child,
		.section [class*=" container"] &:first-child
		{
			margin-bottom: -100px;
		}
	}
}

@include min-screen($lg-width)
{
	.gallery
	{
		& &__item--text { max-width: 555px; }

		.row
		{
			&:nth-of-type(2n+1)
			{
				.gallery__item--text { padding-left: 70px; }
			}

			&:nth-of-type(2n)
			{
				.gallery__item--text { padding-right: 70px; }
			}
		}
	}
}