/* --------------------------------
   custom bootstrap styles
-------------------------------- */

html { font-size: $base-font-size; }

body
{
	font:
	{
		size: rem-calc(15px);
		family: $base-fontFamily-1;
	};
	line-height: 1.538;
	color: #555;
	background-color: $white;
}

.container,
.container-fluid
{
	@extend %pos-relative;
}